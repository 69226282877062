import formKitTheme from '@/formkitTheme';
import { createAutoAnimatePlugin } from '@formkit/addons';
import { nl } from '@formkit/i18n';
import { generateClasses } from '@formkit/themes';
import { defaultConfig as FKdc, plugin as FKp } from '@formkit/vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createPinia } from 'pinia';
import { createApp, h } from 'vue';
import { createI18n } from 'vue-i18n';
import '../css/app.css';
import './bootstrap';
import dayjs from './plugins/dayjs';
import {flareVue} from '@flareapp/flare-vue';
import flare from './plugins/flare';
import { ZiggyVue } from './plugins/ziggy';
import localeMessages from './vue-i18n-locales.generated';
import { Ziggy } from "./ziggy";

const pinia = createPinia();

if (import.meta.env.PROD) {
    flare.light();
}



createInertiaApp({
    progress: { color: '#FFF', delay: 500 },
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        ),
    setup({ el, App, props, plugin }) {
        const currentLocale = props.initialPage.props.locale;

        const i18n = createI18n({
            legacy: false,
            locale: currentLocale,
            fallbackLocale: 'en',
            messages: localeMessages,
        });

        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(pinia)
            .use(flareVue)
            .use(ZiggyVue, { ...Ziggy, locale: currentLocale })
            .use(dayjs, { locale: currentLocale })
            .use(i18n)
            .use(
                FKp,
                FKdc({
                    config: {
                        classes: generateClasses(formKitTheme),
                    },
                    plugins: [createAutoAnimatePlugin({ duration: 150 })],
                    locales: { nl },
                    locale: "nl",
                    globalInjection: true,
                })
            )
            .mount(el);
    },
});
