export default {
    "en": {
        "(and {count} more error)": "(and {count} more error)",
        "(and {count} more errors)": "(and {count} more errors)",
        "*Required fields": "*Required fields",
        "404 - Page not found": "404 - Page not found",
        "{Attribute} {lang}": "{Attribute} {lang}",
        "{count} characters": "{count} characters",
        "Account number": "Account number",
        "Age": "Age",
        "Age *": "Age *",
        "All rights reserved.": "All rights reserved.",
        "Anders": "Anders",
        "answer": "answer",
        "At the moment there are no news items, come back later.": "At the moment there are no news items, come back later.",
        "Back to home": "Back to home",
        "Become a sponsor as well?": "Become a sponsor as well?",
        "Be prepared for less-than-perfect weather (rain gear).": "Be prepared for less-than-perfect weather (rain gear).",
        "Bring plenty of food and drink for the road.": "Bring plenty of food and drink for the road.",
        "Close announcement": "Close announcement",
        "cms:{recommendation}.between.string": "cms:{recommendation}.between.string",
        "Contact": "Contact",
        "Content": "Content",
        "Cookie": "Cookie",
        "Created at": "Created at",
        "Day 1": "Day 1",
        "Day 2": "Day 2",
        "Day 3": "Day 3",
        "Day 4": "Day 4",
        "Description": "Description",
        "description": "description",
        "Disclaimer": "Disclaimer",
        "Discount": "Discount",
        "Discount active": "Discount active",
        "Distance": "Distance",
        "Distance *": "Distance *",
        "Do you have a question, comment or request? Please feel free to contact us.": "Do you have a question, comment or request? Please feel free to contact us.",
        "Dropdown arrow": "Dropdown arrow",
        "E-mail *": "E-mail *",
        "Email": "Email",
        "End": "End",
        "Excerpt": "Excerpt",
        "excerpt": "excerpt",
        "Fill in this form to register! The cost is €5; payable via iDeal (internet banking through your own bank).": "Fill in this form to register! The cost is €5; payable via iDeal (internet banking through your own bank).",
        "Fill in this form to register! The cost is €5; payable via iDeal (internet banking through your own bank) or by normal bank transfer through your bank.": "Fill in this form to register! The cost is €5; payable via iDeal (internet banking through your own bank) or by normal bank transfer through your bank.",
        "First name": "First name",
        "First Name *": "First Name *",
        "Forbidden": "Forbidden",
        "Frequently Asked Questions": "Frequently Asked Questions",
        "Frequently asked questions": "Frequently asked questions",
        "Go back to news overview": "Go back to news overview",
        "Go to page {page}": "Go to page {page}",
        "Group *": "Group *",
        "Group addition": "Group addition",
        "Group addition *": "Group addition *",
        "Hello!": "Hello!",
        "I agree with the": "I agree with the",
        "I agree with the conditions*": "I agree with the conditions*",
        "I agree with the terms and conditions*": "I agree with the terms and conditions*",
        "If this was a mistake, you can try again using the button below": "If this was a mistake, you can try again using the button below",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:",
        "If you did not create an account, no further action is required.": "If you did not create an account, no further action is required.",
        "If you did not request a password reset, no further action is required.": "If you did not request a password reset, no further action is required.",
        "Image": "Image",
        "in the name of": "in the name of",
        "I want to help": "I want to help",
        "Last name": "Last name",
        "Last Name *": "Last Name *",
        "Leave empty for no end date": "Leave empty for no end date",
        "Leave empty to use parent details": "Leave empty to use parent details",
        "Login": "Login",
        "Logout": "Logout",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        "Madde by:": "Madde by:",
        "Made by:": "Made by:",
        "Make sure you have well-worn footwear": "Make sure you have well-worn footwear",
        "Message": "Message",
        "Message *": "Message *",
        "More news": "More news",
        "N/A": "N/A",
        "name": "name",
        "Name *": "Name *",
        "News": "News",
        "No news": "No news",
        "Not Found": "Not Found",
        "of": "of",
        "One moment please...": "One moment please...",
        "Page Expired": "Page Expired",
        "Pagination Navigation": "Pagination Navigation",
        "Parent category": "Parent category",
        "Payment failed": "Payment failed",
        "Please click the button below to verify your email address.": "Please click the button below to verify your email address.",
        "Preparing for hiking": "Preparing for hiking",
        "Preparing for running": "Preparing for running",
        "Privacy declaration": "Privacy declaration",
        "question": "question",
        "Regards": "Regards",
        "Register": "Register",
        "Registration successful": "Registration successful",
        "Reset Password": "Reset Password",
        "Reset Password Notification": "Reset Password Notification",
        "results": "results",
        "Retry payment": "Retry payment",
        "Routes": "Routes",
        "School": "School",
        "School *": "School *",
        "School addition": "School addition",
        "School group": "School group",
        "Secretariat:": "Secretariat:",
        "SEO title": "SEO title",
        "Server Error": "Server Error",
        "Service Unavailable": "Service Unavailable",
        "Showing": "Showing",
        "Sign up": "Sign up",
        "Slug": "Slug",
        "Sponsors": "Sponsors",
        "Start": "Start",
        "Start Avond4Daagse": "Start Avond4Daagse",
        "Starting the Avond4Daagse on May 17, 8 p.m": "Starting the Avond4Daagse on May 17, 8 p.m",
        "Starting the Avond4Daagse on May 23": "Starting the Avond4Daagse on May 23",
        "Status": "Status",
        "Step 1": "Step 1",
        "Step 2": "Step 2",
        "Step 3": "Step 3",
        "Subject *": "Subject *",
        "Submit": "Submit",
        "Terms and Conditions": "Terms and Conditions",
        "Text": "Text",
        "Thanks for registering! Now enjoy the anticipation, go well prepared with the tips below. See you soon!": "Thanks for registering! Now enjoy the anticipation, go well prepared with the tips below. See you soon!",
        "Thank you for your message!": "Thank you for your message!",
        "The {attribute} must contain at least one letter.": "The {attribute} must contain at least one letter.",
        "The {attribute} must contain at least one number.": "The {attribute} must contain at least one number.",
        "The {attribute} must contain at least one symbol.": "The {attribute} must contain at least one symbol.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "The {attribute} must contain at least one uppercase and one lowercase letter.",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.",
        "The given data was invalid.": "The given data was invalid.",
        "This password reset link will expire in {count} minutes.": "This password reset link will expire in {count} minutes.",
        "Tips": "Tips",
        "Title": "Title",
        "title": "title",
        "Title announcement": "Title announcement",
        "to": "to",
        "Toggle navigation": "Toggle navigation",
        "Too Many Requests": "Too Many Requests",
        "Type": "Type",
        "Unauthorized": "Unauthorized",
        "Variants": "Variants",
        "Verify Email Address": "Verify Email Address",
        "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.": "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.",
        "We have received your message. One of our employees will contact you as soon as possible.": "We have received your message. One of our employees will contact you as soon as possible.",
        "We have received your message. We will contact you as soon as possible.": "We have received your message. We will contact you as soon as possible.",
        "We will contact you as soon as possible.": "We will contact you as soon as possible.",
        "Whoops!": "Whoops!",
        "Will you join us again this year?": "Will you join us again this year?",
        "You are receiving this email because we received a password reset request for your account.": "You are receiving this email because we received a password reset request for your account.",
        "You will receive a confirmation email": "You will receive a confirmation email",
        " © Avond4daagse Vinkeveen": "© Avond4daagse Vinkeveen",
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "accepted_if": "The {attribute} must be accepted when {other} is {value}.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} must only contain letters.",
            "alpha_dash": "The {attribute} must only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The {attribute} must only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "array": "The {attribute} must have between {min} and {max} items.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "numeric": "The {attribute} must be between {min} and {max}.",
                "string": "The {attribute} must be between {min} and {max} characters."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "current_password": "The password is incorrect.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "declined": "The {attribute} must be declined.",
            "declined_if": "The {attribute} must be declined when {other} is {value}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "doesnt_end_with": "The {attribute} may not end with one of the following: {values}.",
            "doesnt_start_with": "The {attribute} may not start with one of the following: {values}.",
            "email": "The {attribute} must be a valid email address.",
            "ends_with": "The {attribute} must end with one of the following: {values}.",
            "enum": "The selected {attribute} is invalid.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "gt": {
                "array": "The {attribute} must have more than {value} items.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "numeric": "The {attribute} must be greater than {value}.",
                "string": "The {attribute} must be greater than {value} characters."
            },
            "gte": {
                "array": "The {attribute} must have {value} items or more.",
                "file": "The {attribute} must be greater than or equal to {value} kilobytes.",
                "numeric": "The {attribute} must be greater than or equal to {value}.",
                "string": "The {attribute} must be greater than or equal to {value} characters."
            },
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lowercase": "The {attribute} must be lowercase.",
            "lt": {
                "array": "The {attribute} must have less than {value} items.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "numeric": "The {attribute} must be less than {value}.",
                "string": "The {attribute} must be less than {value} characters."
            },
            "lte": {
                "array": "The {attribute} must not have more than {value} items.",
                "file": "The {attribute} must be less than or equal to {value} kilobytes.",
                "numeric": "The {attribute} must be less than or equal to {value}.",
                "string": "The {attribute} must be less than or equal to {value} characters."
            },
            "mac_address": "The {attribute} must be a valid MAC address.",
            "max": {
                "array": "The {attribute} must not have more than {max} items.",
                "file": "The {attribute} must not be greater than {max} kilobytes.",
                "numeric": "The {attribute} must not be greater than {max}.",
                "string": "The {attribute} must not be greater than {max} characters."
            },
            "max_digits": "The {attribute} must not have more than {max} digits.",
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "array": "The {attribute} must have at least {min} items.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "numeric": "The {attribute} must be at least {min}.",
                "string": "The {attribute} must be at least {min} characters."
            },
            "min_digits": "The {attribute} must have at least {min} digits.",
            "multiple_of": "The {attribute} must be a multiple of {value}.",
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "password": {
                "letters": "The {attribute} must contain at least one letter.",
                "mixed": "The {attribute} must contain at least one uppercase and one lowercase letter.",
                "numbers": "The {attribute} must contain at least one number.",
                "symbols": "The {attribute} must contain at least one symbol.",
                "uncompromised": "The given {attribute} has appeared in a data leak. Please choose a different {attribute}."
            },
            "present": "The {attribute} field must be present.",
            "prohibited": "The {attribute} field is prohibited.",
            "prohibited_if": "The {attribute} field is prohibited when {other} is {value}.",
            "prohibited_unless": "The {attribute} field is prohibited unless {other} is in {values}.",
            "prohibits": "The {attribute} field prohibits {other} from being present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_array_keys": "The {attribute} field must contain entries for: {values}.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_if_accepted": "The {attribute} field is required when {other} is accepted.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} are present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "array": "The {attribute} must contain {size} items.",
                "file": "The {attribute} must be {size} kilobytes.",
                "numeric": "The {attribute} must be {size}.",
                "string": "The {attribute} must be {size} characters."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}.",
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid timezone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "uppercase": "The {attribute} must be uppercase.",
            "url": "The {attribute} must be a valid URL.",
            "uuid": "The {attribute} must be a valid UUID.",
            "attributes": {
                "address": "address",
                "age": "age",
                "amount": "amount",
                "area": "area",
                "available": "available",
                "birthday": "birthday",
                "body": "body",
                "city": "city",
                "content": "content",
                "country": "country",
                "created_at": "created at",
                "creator": "creator",
                "current_password": "current password",
                "date": "date",
                "date_of_birth": "date of birth",
                "day": "day",
                "deleted_at": "deleted at",
                "description": "description",
                "district": "district",
                "duration": "duration",
                "email": "email",
                "excerpt": "excerpt",
                "filter": "filter",
                "first_name": "first name",
                "gender": "gender",
                "group": "group",
                "hour": "hour",
                "image": "image",
                "last_name": "last name",
                "lesson": "lesson",
                "line_address_1": "line address 1",
                "line_address_2": "line address 2",
                "message": "message",
                "middle_name": "middle name",
                "minute": "minute",
                "mobile": "mobile",
                "month": "month",
                "name": "name",
                "national_code": "national code",
                "number": "number",
                "password": "password",
                "password_confirmation": "password confirmation",
                "phone": "phone",
                "photo": "photo",
                "postal_code": "postal code",
                "price": "price",
                "province": "province",
                "recaptcha_response_field": "recaptcha response field",
                "remember": "remember",
                "restored_at": "restored at",
                "result_text_under_image": "result text under image",
                "role": "role",
                "second": "second",
                "sex": "sex",
                "short_text": "short text",
                "size": "size",
                "state": "state",
                "street": "street",
                "student": "student",
                "subject": "subject",
                "teacher": "teacher",
                "terms": "terms",
                "test_description": "test description",
                "test_locale": "test locale",
                "test_name": "test name",
                "text": "text",
                "time": "time",
                "title": "title",
                "updated_at": "updated at",
                "username": "username",
                "year": "year"
            }
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "password": "The password is incorrect.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds."
        },
        "pagination": {
            "next": "Next &raquo;",
            "previous": "&laquo; Previous"
        },
        "passwords": {
            "reset": "Your password has been reset!",
            "sent": "We have emailed your password reset link!",
            "throttled": "Please wait before retrying.",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that email address."
        }
    },
    "nl": {
        "(and {count} more error)": "(and {count} more error)",
        "(and {count} more errors)": "(and {count} more errors)",
        "*Required fields": "*Verplichte velden",
        "17 to 20 May 2023": "17 t/m 20 mei 2023",
        "23 to 25 May 2023": "23 t/m 25 mei 2023",
        "404 - Page not found": "404 - Pagina niet gevonden",
        "{Attribute} {lang}": "{Attribute} {lang}",
        "{count} characters": "{count} characters",
        "Account number": "Rek. nr.",
        "Age": "Leeftijd",
        "Age *": "Leeftijd *",
        "All rights reserved.": "Alle rechten voorbehouden.",
        "Anders": "Anders",
        "Answer": "Antwoord",
        "answer": "antwoord",
        "At the moment there are no news items, come back later.": "Op dit moment zijn er geen nieuwsberichten, kom op een later moment terug.",
        "Back to home": "Terug naar home",
        "Become a sponsor as well?": "Ook sponsor worden?",
        "Be prepared for less-than-perfect weather (rain gear).": "Wees bedacht op minder mooi weer (regenkleding).",
        "Bring plenty of food and drink for the road.": "Neem voldoende eten en drinken mee voor onderweg.",
        "Close announcement": "Mededeling sluiten",
        "cms:{recommendation}.between.string": "cms:{recommendation}.between.string",
        "Contact": "Contact",
        "Content": "Inhoud",
        "Cookie": "Cookie",
        "Created at": "Aangemaakt op ",
        "Day 1": "Dag 1",
        "Day 2": "Dag 2",
        "Day 3": "Dag 3",
        "Day 4": "Dag 4",
        "Dear {name}": "Beste {name}",
        "Description": "Beschrijving",
        "description": "beschrijving",
        "Disclaimer": "Disclaimer",
        "Discount": "Korting",
        "Discount active": "Korting actief",
        "Distance": "Afstand",
        "Distance *": "Afstand *",
        "Do you have a question, comment or request? Please feel free to contact us.": "Heb je een vraag, opmerking of verzoek? Neem dan gerust contact met ons op.",
        "Dropdown arrow": "Dropdown pijl",
        "Due to Vinkefest this year for three days from 23 to 25 May.": "i.v.m. Vinkefest dit jaar drie dagen van 23 t/m 25 mei.",
        "E-mail *": "E-mail *",
        "Email": "E-mail",
        "End": "Einde",
        "Excerpt": "Uittreksel",
        "excerpt": "Korte omschrijving",
        "Fill in this form to register! The cost is €5; payable via iDeal (internet banking through your own bank).": "Vul dit formulier in om je in te schrijven! De kosten bedragen €5,-; te voldoen via iDeal (internetbankieren via je eigen bank).",
        "First name": "Voornaam",
        "First Name *": "Voornaam *",
        "Forbidden": "Geen toegang",
        "Frequently Asked Questions": "Veelgestelde vragen",
        "Frequently asked questions": "Veelgestelde vragen",
        "Friday there will be no Koek & Zopie on the road.": "Vrijdag zal er geen Koek & Zopie onderweg zijn.",
        "Go back to news overview": "Ga terug naar het nieuwsoverzicht",
        "Go to page {page}": "Ga naar pagina {page}",
        "Group *": "Groep *",
        "Group addition": "Groepstoevoeging",
        "Have fun hiking!": "Veel wandel plezier!",
        "Hello!": "Hallo!",
        "I agree with the": "Ik ben het eens met de",
        "I agree with the conditions*": "Ik ga akkoord met de voorwaarden",
        "I agree with the terms and conditions*": "Ik ga akkoord met de algemene voorwaarden",
        "If enabled, and no image is uploaded, the banner from the homepage will be shown.": "Indien ingeschakeld, en er is geen afbeelding geupload, dan wordt de banner van de homepage getoond.",
        "If is active and announcement bar is still not visible, please clear your cookies. Once closed hidebar it will remember it untill clearing cookies.": "Indien actief en de mededeling balk is nog steeds niet zichtbaar, wis dan uw cookies. Als de mededeking een keer is weggeklikt, wordt dit onthouden totdat de cookies zijn verwijderd.",
        "If this was a mistake, you can try again using the button below": "Als dit een vergissing was, kunt u het opnieuw proberen via de onderstaande knop",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "Als je problemen hebt met de \"{actionText}\" knop, kopieer en plak de URL hierondernin je webbrowser:",
        "If you did not create an account, no further action is required.": "Als je geen account hebt aangemaakt hoef je verder niets te doen.",
        "If you did not request a password reset, no further action is required.": "Als je geen wachtwoordherstel hebt aangevraagd, hoef je verder niets te doen.",
        "If you haven't had a stamp card through school on Tuesday, you can come pick one up in the Buoy. Also those who are not running through a school can come and get their punch card in the Buoy. We will be present as of 5 p.m.": "Als je dinsdag via school nog geen stempelkaart hebt gehad, dan kun je deze op komen halen in de Boei. Ook diegene die niet via een school lopen kunnen hun stempelkaart in de Boei komen halen. Wij zijn aanwezig vanaf 17.00 uur.",
        "If you know of classmates who have not registered (yet), please encourage them. They can sign up through the site and Tuesday night in the Buoy is also still an opportunity to sign up.": "Weet je klasgenoten die zich (nog) niet hebben ingeschreven, moedig die dan aan. Zij kunnen zich inschrijven via de site en dinsdagavond is ook in de Boei nog de gelegenheid om je in te laten schrijven.",
        "Image": "Afbeelding",
        "in the name of": "t.n.v",
        "I want to help": "Ik wil helpen",
        "Jumbo Avond4daagse Vinkeveen message for: {firstname} {lastname}": "Jumbo Avond4daagse Vinkeveen bericht voor: {firstname} {lastname}",
        "Last name": "Achternaam",
        "Last Name *": "Achternaam *",
        "Leave empty for no end date": "Leeg laten voor geen einddatum",
        "Leave empty to use parent details": "Leeg laten om hoofdgegevens te gebruiken",
        "Login": "Inloggen",
        "Logout": "Uitloggen",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        "Made by:": "Gerealiseerd door:",
        "Make sure you have well-worn footwear": "Zorg voor goed ingelopen schoeisel.",
        "Message": "Bericht",
        "Message *": "Bericht *",
        "More news": "Meer nieuws",
        "N/A": "N.v.t",
        "name": "naam",
        "Name *": "Naam *",
        "News": "Nieuws",
        "No news": "Geen nieuwsberichten",
        "Not Found": "Niet gevonden",
        "of": "van",
        "One moment please...": "Een moment alstublieft...",
        "On Friday, of course, the parade will take place. The 10 km will start at 18{00} sharp and the 5 km at 18{45}. We will gather on the Ringdijk and leave there at 20{00}, via the Heulweg - Kerkelanden (bicycle path) - Achterveld in the direction of the Buoy. The parade will be held near Achterveld.": "Vrijdag zal natuurlijk het defilé plaatsvinden. De 10 km start dan stipt om 18{00} uur en de 5 km om 18{45} uur. We verzamelen op de Ringdijk en vertrekken daar om 20{00} uur, via de Heulweg - Kerkelanden (fietspad) - Achterveld richting de Boei. In de buurt van Achterveld zal het defilé afgenomen worden.",
        "Page Expired": "Pagina niet meer geldig",
        "Pagination Navigation": "Paginanavigatie",
        "Parent category": "Hoofdcategorie",
        "Payment failed": "Betaling mislukt",
        "Please click the button below to verify your email address.": "Klik op de knop hieronder om je e-mailadres te verifiëren.",
        "Preparing for hiking": "Voorbereiding op het wandelen",
        "Preparing for running": "Voorbereiding op het rennen",
        "Privacy declaration": "Privacyverklaring",
        "Question": "Vraag",
        "question": "vraag",
        "Regards": "Met vriendelijke groet",
        "Register": "Registreren",
        "Registration has come through all right and we look forward to seeing you at the 12th Jumbo Avond4daagse Vinkeveen.": "De inschrijving is helemaal goed doorgekomen en wij verheugen je te zien bij de 14e Jumbo Avond4daagse Vinkeveen van 23 t/m 25 mei 2023. ",
        "Registration successful": "Registratie succesvol",
        "Reset Password": "Wachtwoord herstellen",
        "Reset Password Notification": "Wachtwoordherstel notificatie",
        "results": "resultaten",
        "Retry payment": "Betaling opnieuw proberen",
        "Routes": "Routes",
        "School": "School",
        "School *": "School *",
        "School addition": "Groep toevoeging",
        "School group": "Groep",
        "Secretariat:": "Secretariaat:",
        "SEO title": "SEO titel",
        "Server Error": "Server fout",
        "Service Unavailable": "Website onbeschikbaar",
        "Showing": "Toont",
        "Sign up": "Aanmelden",
        "Signup successful": "Inschrijving geslaagd",
        "Slug": "Permalink",
        "Sponsors": "Sponsors",
        "Start": "Start",
        "Start Avond4Daagse": "Start Avond4Daagse",
        "Starting the Avond4Daagse on May 17, 8 p.m": "Start Avond4Daagse op 17 - 20 mei",
        "Starting the Avond4Daagse on May 23": "Start Avond4Daagse op 23 mei",
        "Status": "Betaalstatus",
        "Step 1": "Stap 1",
        "Step 2": "Stap 2",
        "Step 3": "Stap 3",
        "Subject *": "Onderwerp *",
        "Submit": "Versturen",
        "Terms and Conditions": "Algemene Voorwaarden",
        "Text": "Tekst",
        "Thanks for registering! Now enjoy the anticipation, go well prepared with the tips below. See you soon!": "Bedankt voor je inschrijving! Bereid je voor op de Avond4Daagse met onderstaande tips.",
        "Thank you for your message!": "Bedankt voor uw bericht!",
        "The {attribute} must contain at least one letter.": "Het {attribute} moet minimaal één letter bevatten.",
        "The {attribute} must contain at least one number.": "Het {attribute} moet minimaal één cijfer bevatten.",
        "The {attribute} must contain at least one symbol.": "Het {attribute} moet minimaal één symbool bevatten.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "Het {attribute} moet minimaal één hoofdletter en één kleine letter bevatten.",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "Het {attribute} is aangetroffen in een datalek. Geef een ander {attribute}.",
        "The given data was invalid.": "De gegeven data was ongeldig.",
        "This password reset link will expire in {count} minutes.": "Deze link om je wachtwoord te herstellen verloopt over {count} minuten.",
        "This registration, payment and website is supported by": "Deze inschrijving, betaling en website wordt ondersteund door",
        "This subscription and website is made possible by": "Deze inschrijving en website is mogelijk gemaakt door",
        "Tips": "Tips",
        "Title": "Titel",
        "title": "titel",
        "Title announcement": "Titel mededeling",
        "to": "tot",
        "Toggle navigation": "Schakel navigatie",
        "Too Many Requests": "Te veel serververzoeken",
        "Type": "Type",
        "Unauthorized": "Onbevoegd",
        "Variants": "Varianten",
        "Verify Email Address": "Verifieer e-mailadres",
        "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.": "We hebben ons best gedaan, maar het lijkt erop dat de door u opgevraagde pagina niet (meer) bestaat of dat deze is verplaatst.",
        "We have received your message. One of our employees will contact you as soon as possible.": "We hebben uw bericht ontvangen. Een van onze medewerkers zal zo spoedig mogelijk contact met u opnemen.",
        "We have received your message. We will contact you as soon as possible.": "We hebben uw bericht ontvangen. We nemen zo spoedig mogelijk contact met u op.",
        "We have received your message and will contact you as soon as possible.": "We hebben uw bericht ontvangen. We nemen zo spoedig mogelijk contact met u op",
        "We will contact you as soon as possible.": "We nemen zo spoedig mogelijk contact met u op.",
        "Whoops!": "Oeps!",
        "Will you join us again this year?": "Loop jij dit jaar ook weer mee?",
        "You are receiving this email because we received a password reset request for your account.": "Je ontvangt deze e-mail omdat we een wachtwoordherstel verzoek hebben ontvangen voor je account.",
        "You can find this year's routes at:": "De routes van dit jaar kun je vinden ",
        "You will receive a confirmation email": "U ontvangt een bevestigingsmail",
        " © Avond4daagse Vinkeveen": "© Avond4daagse Vinkeveen",
        "validation": {
            "accepted": "{Attribute} moet worden geaccepteerd.",
            "accepted_if": "{Attribute} moet worden geaccepteerd als {other} {value} is.",
            "active_url": "{Attribute} is geen geldige URL.",
            "after": "{Attribute} moet een datum na {date} zijn.",
            "after_or_equal": "{Attribute} moet een datum na of gelijk aan {date} zijn.",
            "alpha": "{Attribute} mag alleen letters bevatten.",
            "alpha_dash": "{Attribute} mag alleen letters, nummers, underscores (_) en streepjes (-) bevatten.",
            "alpha_num": "{Attribute} mag alleen letters en nummers bevatten.",
            "array": "{Attribute} moet geselecteerde elementen bevatten.",
            "before": "{Attribute} moet een datum vóór {date} zijn.",
            "before_or_equal": "{Attribute} moet een datum vóór of gelijk aan {date} zijn.",
            "between": {
                "array": "{Attribute} moet tussen {min} en {max} waardes bevatten.",
                "file": "{Attribute} moet tussen {min} en {max} kilobytes zijn.",
                "numeric": "{Attribute} moet tussen {min} en {max} zijn.",
                "string": "{Attribute} moet tussen {min} en {max} karakters zijn."
            },
            "boolean": "{Attribute} moet ja of nee zijn.",
            "confirmed": "Bevestiging van {attribute} komt niet overeen.",
            "current_password": "Huidig wachtwoord is onjuist.",
            "date": "{Attribute} moet een datum bevatten.",
            "date_equals": "{Attribute} moet een datum gelijk aan {date} zijn.",
            "date_format": "{Attribute} voldoet niet aan het formaat {format}.",
            "declined": "{Attribute} moet afgewezen worden.",
            "declined_if": "{Attribute} moet afgewezen worden wanneer {other} gelijk is aan {value}.",
            "different": "{Attribute} en {other} moeten verschillend zijn.",
            "digits": "{Attribute} moet bestaan uit {digits} cijfers.",
            "digits_between": "{Attribute} moet bestaan uit minimaal {min} en maximaal {max} cijfers.",
            "dimensions": "{Attribute} heeft geen geldige afmetingen voor afbeeldingen.",
            "distinct": "{Attribute} heeft een dubbele waarde.",
            "doesnt_end_with": "The {attribute} may not end with one of the following: {values}.",
            "doesnt_start_with": "{Attribute} mag niet beginnen met één van de volgende waarden: {values}.",
            "email": "{Attribute} is geen geldig e-mailadres.",
            "ends_with": "{Attribute} moet met één van de volgende waarden eindigen: {values}.",
            "enum": "Gekozen {attribute} is ongeldig.",
            "exists": "{Attribute} bestaat niet.",
            "file": "{Attribute} moet een bestand zijn.",
            "filled": "{Attribute} is verplicht.",
            "gt": {
                "array": "{Attribute} moet meer dan {value} waardes bevatten.",
                "file": "{Attribute} moet groter zijn dan {value} kilobytes.",
                "numeric": "{Attribute} moet groter zijn dan {value}.",
                "string": "{Attribute} moet meer dan {value} tekens bevatten."
            },
            "gte": {
                "array": "{Attribute} moet {value} of meer waardes bevatten.",
                "file": "{Attribute} moet groter of gelijk zijn aan {value} kilobytes.",
                "numeric": "{Attribute} moet groter of gelijk zijn aan {value}.",
                "string": "{Attribute} moet minimaal {value} tekens bevatten."
            },
            "image": "{Attribute} moet een afbeelding zijn.",
            "in": "{Attribute} is ongeldig.",
            "in_array": "{Attribute} bestaat niet in {other}.",
            "integer": "{Attribute} moet een getal zijn.",
            "ip": "{Attribute} moet een geldig IP-adres zijn.",
            "ipv4": "{Attribute} moet een geldig IPv4-adres zijn.",
            "ipv6": "{Attribute} moet een geldig IPv6-adres zijn.",
            "json": "{Attribute} moet een geldige JSON-string zijn.",
            "lowercase": "The {attribute} must be lowercase.",
            "lt": {
                "array": "{Attribute} moet minder dan {value} waardes bevatten.",
                "file": "{Attribute} moet kleiner zijn dan {value} kilobytes.",
                "numeric": "{Attribute} moet kleiner zijn dan {value}.",
                "string": "{Attribute} moet minder dan {value} tekens bevatten."
            },
            "lte": {
                "array": "{Attribute} moet {value} of minder waardes bevatten.",
                "file": "{Attribute} moet kleiner of gelijk zijn aan {value} kilobytes.",
                "numeric": "{Attribute} moet kleiner of gelijk zijn aan {value}.",
                "string": "{Attribute} moet maximaal {value} tekens bevatten."
            },
            "mac_address": "{Attribute} moet een geldig MAC-adres zijn.",
            "max": {
                "array": "{Attribute} mag niet meer dan {max} waardes bevatten.",
                "file": "{Attribute} mag niet meer dan {max} kilobytes zijn.",
                "numeric": "{Attribute} mag niet hoger dan {max} zijn.",
                "string": "{Attribute} mag niet uit meer dan {max} tekens bestaan."
            },
            "max_digits": "The {attribute} must not have more than {max} digits.",
            "mimes": "{Attribute} moet een bestand zijn van het bestandstype {values}.",
            "mimetypes": "{Attribute} moet een bestand zijn van het bestandstype {values}.",
            "min": {
                "array": "{Attribute} moet minimaal {min} waardes bevatten.",
                "file": "{Attribute} moet minimaal {min} kilobytes zijn.",
                "numeric": "{Attribute} moet minimaal {min} zijn.",
                "string": "{Attribute} moet minimaal {min} tekens zijn."
            },
            "min_digits": "The {attribute} must have at least {min} digits.",
            "multiple_of": "{Attribute} moet een veelvoud van {value} zijn.",
            "not_in": "{Attribute} is ongeldig.",
            "not_regex": "Het formaat van {attribute} is ongeldig.",
            "numeric": "{Attribute} moet een getal zijn.",
            "password": {
                "letters": "{Attribute} moet minimaal één letter bevatten.",
                "mixed": "{Attribute} moet minimaal één kleine letter en één hoofdletter bevatten.",
                "numbers": "{Attribute} moet minimaal één cijfer bevatten.",
                "symbols": "{Attribute} moet minimaal één vreemd teken bevatten.",
                "uncompromised": "Het opgegeven {attribute} komt voor in een datalek. Kies een ander {attribute}."
            },
            "present": "{Attribute} moet aanwezig zijn.",
            "prohibited": "{Attribute} is niet toegestaan.",
            "prohibited_if": "{Attribute} is niet toegestaan indien {other} gelijk is aan {value}.",
            "prohibited_unless": "{Attribute} is niet toegestaan tenzij {other} gelijk is aan {values}.",
            "prohibits": "{Attribute} is niet toegestaan in combinatie met {other}.",
            "regex": "Het formaat van {attribute} is ongeldig.",
            "required": "{Attribute} is verplicht.",
            "required_array_keys": "{Attribute} moet waardes bevatten voor {values}.",
            "required_if": "{Attribute} is verplicht indien {other} gelijk is aan {value}.",
            "required_if_accepted": "The {attribute} field is required when {other} is accepted.",
            "required_unless": "{Attribute} is verplicht tenzij {other} gelijk is aan {values}.",
            "required_with": "{Attribute} is verplicht in combinatie met {values}.",
            "required_with_all": "{Attribute} is verplicht in combinatie met {values}.",
            "required_without": "{Attribute} is verplicht als {values} niet ingevuld is.",
            "required_without_all": "{Attribute} is verplicht als {values} niet ingevuld zijn.",
            "same": "{Attribute} en {other} moeten overeenkomen.",
            "size": {
                "array": "{Attribute} moet {size} waardes bevatten.",
                "file": "{Attribute} moet {size} kilobytes groot zijn.",
                "numeric": "{Attribute} moet {size} zijn.",
                "string": "{Attribute} moet {size} tekens zijn."
            },
            "starts_with": "{Attribute} moet beginnen met een van de volgende: {values}.",
            "string": "{Attribute} moet een tekst zijn.",
            "timezone": "{Attribute} moet een geldige tijdzone zijn.",
            "unique": "{Attribute} is al in gebruik.",
            "uploaded": "Het uploaden van {attribute} is mislukt.",
            "uppercase": "The {attribute} must be uppercase.",
            "url": "{Attribute} moet een geldige URL zijn.",
            "uuid": "{Attribute} moet een geldige UUID zijn.",
            "attributes": {
                "address": "adres",
                "age": "leeftijd",
                "amount": "bedrag",
                "area": "regio",
                "available": "beschikbaar",
                "birthday": "verjaardag",
                "body": "body",
                "city": "stad",
                "content": "inhoud",
                "country": "land",
                "created_at": "aangemaakt op",
                "creator": "maker",
                "current_password": "huidig wachtwoord",
                "date": "datum",
                "date_of_birth": "geboortedatum",
                "day": "dag",
                "deleted_at": "verwijderd op",
                "description": "omschrijving",
                "district": "wijk",
                "duration": "tijdsduur",
                "email": "e-mailadres",
                "excerpt": "uittreksel",
                "filter": "filter",
                "first_name": "voornaam",
                "gender": "geslacht",
                "group": "groep",
                "hour": "uur",
                "image": "afbeelding",
                "last_name": "achternaam",
                "lesson": "les",
                "line_address_1": "adresregel 1",
                "line_address_2": "adresregel 2",
                "message": "bericht",
                "middle_name": "tweede naam",
                "minute": "minuut",
                "mobile": "mobiel",
                "month": "maand",
                "name": "naam",
                "national_code": "landcode",
                "number": "nummer",
                "password": "wachtwoord",
                "password_confirmation": "wachtwoordbevestiging",
                "phone": "telefoonnummer",
                "photo": "foto",
                "postal_code": "postcode",
                "price": "prijs",
                "province": "provincie",
                "recaptcha_response_field": "recaptcha antwoordveld",
                "remember": "onthouden",
                "restored_at": "hersteld op",
                "result_text_under_image": "antwoord tekst onder afbeelding",
                "role": "rol",
                "second": "seconde",
                "sex": "geslacht",
                "short_text": "korte tekst",
                "size": "grootte",
                "state": "staat",
                "street": "straatnaam",
                "student": "student",
                "subject": "onderwerp",
                "teacher": "docent",
                "terms": "voorwaarden",
                "test_description": "test beschrijving",
                "test_locale": "test landinstelling",
                "test_name": "test naam",
                "text": "tekst",
                "time": "tijd",
                "title": "titel",
                "updated_at": "bijgewerkt op",
                "username": "gebruikersnaam",
                "year": "jaar"
            }
        },
        "routes": {
            "thank-you-for-sign-up": "bedankt-voor-je-inschrijving",
            "terms-and-conditions": "algemene-voorwaarden",
            "privacy-declaration": "privacy-verklaring"
        },
        "auth": {
            "failed": "Deze combinatie van e-mailadres en wachtwoord is niet geldig.",
            "password": "Wachtwoord is onjuist.",
            "throttle": "Te veel mislukte aanmeldpogingen. Probeer het nog eens over {seconds} seconden."
        },
        "pagination": {
            "next": "Volgende &raquo;",
            "previous": "&laquo; Vorige"
        },
        "passwords": {
            "reset": "Het wachtwoord van uw account is gewijzigd.",
            "sent": "We hebben een e-mail verstuurd met instructies om een nieuw wachtwoord in te stellen.",
            "throttled": "Gelieve even te wachten en het dan opnieuw te proberen.",
            "token": "Dit wachtwoordhersteltoken is niet geldig.",
            "user": "Geen gebruiker bekend met het e-mailadres."
        }
    }
}
